header {
    flex-grow: 1;
    display: grid;
    width: 100%;
    position: relative;
    background-color: rgba(0, 0, 0, 0.2);

    /* background: linear-gradient(to top, transparent, rgb(39, 39, 39)); */
}

ul {
    display: flex;
    flex-direction: row;
    margin: 0;
    width: 100%;
    height: 100%;
    flex: auto;
}
ul > * {
    padding: 1rem;
}
li > * {
    text-align: center;
    margin-top: auto;
    margin-bottom: auto;
    color: black;
}
nav{
    height: 100%;
}

li {
    margin: 0;
    font-size: 1rem;
    text-decoration: none;
    list-style-type: none;
    color: black;
}

.rs {
    font-family: 'Abril Fatface', cursive; 
    text-align: center;
    font-size: large;
    font-weight: 1000;
    font-size: 30px;
}

.about {
    text-align: left;
}

.work {
    text-align: right;
}
