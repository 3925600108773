.progress-bar-container{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0, 1);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#progress {
    width: 30%;
    margin-top: 0.5%;
    height: 2%;
}

label {
    color: white;
    font-size: 2rem;
}