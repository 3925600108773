.home {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex-grow: 20;
    top: 0;
    background-repeat: no-repeat;
    align-items: center;
    background-position: 50%;
    background-size: cover;
    transition: background-color 5s;
    background-color: rgba(0, 0, 0, 0.2);
    padding: .5rem;
    pointer-events:none;
}

.home > * {
    color: rgb(0, 0, 0);
}

#aboutDiv {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex-grow: 20;
    top: 0;
    background-repeat: no-repeat;
    align-items: flex-start;
    background-position: 50%;
    background-size: cover;
    transition: opacity 5s;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 3rem;
    padding-top: 5%;
    padding-left: 30%;
    padding-right: 30%;
}

.designwork {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex-grow: 20;
    top: 0;
    background-repeat: no-repeat;
    align-items: center;
    background-position: 50%;
    background-size: cover;
    transition: background-color 5s;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 10rem;
}

.work {
    text-align: left;
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    flex-grow: 20;
    top: 0;
    background-repeat: no-repeat;
    align-items: center;
    background-position: 50%;
    background-size: cover;
    transition: background-color 5s;
    background-color: rgba(0, 0, 0, 0.2);
    padding: 3rem;
}

#workText {
    font-size: 1.2rem;
    transition: font-size 1s;

}

#workItem {
    list-style-type: disc;
    color: white;
}

#workItem:hover > #workText {
    color: aqua;
    font-size: 1.5rem;
    font-weight: bold;
}

#workItem:hover {
    color: aqua;
}

.worklist {
    display: flex;
    flex-direction: column;
}
.intro-title {
    margin-top: 0rem;
}

h1 {
    font-size: 3rem;
    text-align: left;
    justify-content: left;
}

h2 {
    text-align: left;
    font-size: 1.5rem;
    margin-top: .6rem;
    margin-bottom: .6rem;
}

li {
    font-size: 30px;
}

p{
    text-align-last: end;
    margin-right: 3rem;
}

@media (max-width: 1300px) {
    #aboutDiv {
        padding: 2rem;
        padding-left: 20%;
        padding-right: 20%;
    }
  }
  @media (max-width: 600px) {
    #aboutDiv {
        padding: 2rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    a {
        font-size: .8rem;
        padding: .5rem;
    }
    p {
        font-size: .8rem;
    }
  }

  @media (max-width: 500px) {
    #aboutDiv {
        padding: 1rem;
    }
    h1 {
        font-size: 1.8rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    a {
        font-size: .8rem;
        padding: .5rem;
    }
    p {
        font-size: .8rem;
    }
  }

  @media (max-height: 700px) { 
    h1 {
        font-size: 2rem;
    }
    h2 {
        font-size: 1.3rem;
    }
    a {
        font-size: .8rem;
        padding: .5rem;
    }
    p {
        font-size: .8rem;
    }
  }